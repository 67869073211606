export const enum INSTITUTE_TYPE {
    IES_PUBLIC = 'IES_PUBLIC',
    IES_PRIVATE = 'IES_PRIVATE',
    IES_COMMUNITY = 'IES_COMMUNITY',
    IES_TECH = 'IES_TECH',
    EDUCATIONAL_NETWORK = 'EDUCATIONAL_NETWORK',
    GOVERNMENT_INSTITUTION = 'GOVERNMENT_INSTITUTION',
    CONSULTING_ASSISTANCE = 'CONSULTING_ASSISTANCE',
    TECHNOLOGY_PROVIDER = 'TECHNOLOGY_PROVIDER'
}
