interface IAspectRatio {
    name: string;
    value: number;
}

type AspectRatios = IAspectRatio[];

export const ASPECT_RATIOS: AspectRatios = [
    { name: '4:3', value: 4 / 3 },
    { name: '3:2', value: 3 / 2 },
    { name: '1:1', value: 1 / 1 },
    { name: '2:3', value: 2 / 3 },
    { name: '3:4', value: 3 / 4 },
];
